import { h } from 'hyperapp';

export default function DotDot() {
  return (
    <span class="dotdot">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  );
}
